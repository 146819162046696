import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { UpdateProfileVariables } from "@energylab/shared-components/models/graphql/types";
import { profileStructure } from "../profileStructure";

const NATIIONALITY = "BEL";

export const profileConfig: ProfileConfig = {
    setFields: [
        { nationality: NATIIONALITY }
    ],
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex"
    ],
    structure: profileStructure,
    formToValues: (values, originalValues): UpdateProfileVariables => {
        if (!originalValues) {
            throw Error("Can not update profile when not logged in");
        }

        let nickname: string | undefined;
        let publicDisplayNameType = "fullname";
        if (values.useNickname === true) {
            nickname = String(values.nickname);
            publicDisplayNameType = "nickname";
        }

        return {
            profile: {
                ...originalValues,
                givenName: String(values.givenName),
                familyName: String(values.familyName),
                profile: {
                    ...originalValues.profile,
                    birthDate: values.birthDate,
                    nationality: values.nationality,
                    sex: values.sex,
                    hasAcceptedAllPolicies: Boolean(values.privacy && values.disclaimer && values.cookyPolicy)
                },
                privacy: {
                    ...originalValues.privacy,
                    publicDisplayNameType,
                    showActivities: Boolean(values.showActivities),
                    showAsNewMember: Boolean(values.showAsNewMember),
                    nickname
                }
            }
        };
    },
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profile = values.profile || {};

        return {
            givenName: values.givenName,
            familyName: values.familyName,
            nationality: profile.nationality || NATIIONALITY,
            birthDate: profile.birthDate,
            sex: profile.sex,
            email: values.email,
            useNickname: values.privacy.publicDisplayNameType === "nickname",
            nickname: values.privacy.nickname
        };
    }
};
