import { dashboardPlacesConfig as defaultConfig, DashboardPlacesConfig } from "@energylab/shared-components/constants/config/dashboardPlacesConfig";

export const dashboardPlacesConfig: DashboardPlacesConfig = {
    ...defaultConfig,
    ambassadors: ["dashboard_ambassadors"],
    articles: ["dashboard_articles"],
    challenges: ["dashboard_challenges"],
    events: ["dashboard_events"],
    homePage: ["dashboard_kpmg"],
    gallery: ["dashboard_gallery"]
};
