import { RedirectType } from "@energylab/shared-components/routes/routes";
import { lazy } from "react";

const ContentCardOverviewContainer = lazy(() => import("@energylab/shared-components/modules/benefits/contentCardOverview/contentCardOverviewContainer"));
const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));

export const ROUTES = [
    {
        path: "wellbeing",
        element: <ContentCardOverviewContainer
            introKey="wellbeing"
            metaDataKey="wellbeing"
            contentType="wellbeing"
            detailRoute="content" />
    },
    {
        path: "journeys",
        element: <ContentContainer type="journeys" />
    }
]

export const REDIRECTS: RedirectType[] = [];
