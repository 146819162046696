import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { compareToFirstPasswordCreator } from "@energylab/shared-components/utils/compareToFirstPassword";
import * as React from "react";
import * as environment from "environment";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";

export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: []
        },
        {
            title: "register.basicStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    required: true,
                    validationRules: [
                        { type: "email", message: "auth.error.invalidEmail" }
                    ]
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    required: true
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: "auth.error.notEqualPassword"
                        }
                    ],
                    required: true
                }
            ]
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "form.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                {
                    title: "form.sex",
                    type: "radio",
                    options: [{ title: <FormatMessage id="male" />, value: "male" }, { title: <FormatMessage id="female" />, value: "female" }],
                    property: "sex",
                    required: true
                },
                {
                    title: "form.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    validationRules: [
                        {
                            validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                        }
                    ]
                }
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "auth.fields.showActivities",
                    type: "checkbox",
                    property: "showActivities"
                }
            ]
        }
    ]
};
