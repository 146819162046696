import { SeoConfig } from "@energylab/shared-components/constants/config/seoConfig";

export const seo: SeoConfig = {
    pageTitle: "Together @ Kpmg",
    title: {
        nl: "Together @ Kpmg",
        fr: "Together @ Kpmg",
        en: "Together @ Kpmg"
    },
    description: {
        nl: "",
        fr: "",
        en: ""
    },
    image: "",
    site: ""
};
