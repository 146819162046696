import { ContentConfig } from "@energylab/shared-components/constants/config/contentConfig";

export const contentConfig: ContentConfig = {
    showAuthor: true,
    useThemes: true,
    previewType: "card",
    enablePageBanner: true,
    pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
    pageBannerOverlay: true,
    alignment: "center"
};
