import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { articleTagsConfig } from "./constants/config/articleTagsConfig";
import { auth } from "./constants/config/authConfig";
import { bannerConfig } from "./constants/config/bannerConfig";
import { challengeConfig } from "./constants/config/challengeConfig";
import { coinsConfig } from "./constants/config/coinsConfig";
import { commentsConfig } from "./constants/config/commentsConfig";
import { contactConfig } from "./constants/config/contactConfig";
import { contentConfig } from "./constants/config/contentConfig";
import { dashboardPlacesConfig } from "./constants/config/dashboardPlacesConfig";
import { departmentsConfig } from "./constants/config/departmentsConfig";
import { eventsConfig } from "./constants/config/eventsConfig";
import { footerConfig } from "./constants/config/footerConfig";
import { galleryConfig } from "./constants/config/galleryConfig";
import { leaderboardConfig } from "./constants/config/leaderboardConfig";
import { likesConfig } from "./constants/config/likesConfig";
import { logoutConfig } from "./constants/config/logoutConfig";
import { nationalities } from "./constants/config/nationalitiesConfig";
import { newUsersConfig } from "./constants/config/newUsersConfig";
import { nicknameConfig } from "./constants/config/nicknameConfig";
import { notificationsConfig } from "./constants/config/notificationsConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { providers } from "./constants/config/providersConfig";
import { registerConfig } from "./constants/config/registerConfig";
import { resetPasswordConfig } from "./constants/config/resetPasswordConfig";
import { routeConfig } from "./constants/config/routeConfig";
import { seo } from "./constants/config/seoConfig";
import { socialConfig } from "./constants/config/socialConfig";
import { socialShareConfig } from "./constants/config/socialShareConfig";
import { webshopConfig } from "./constants/config/webshopConfig";
import { navbarConfig } from "./constants/config/navbarConfig";

export const config: ConfigReducerState = {
    profileConfig,
    password: {
        requireOldPassword: false,
        minLength: 8,
        requireLetter: true,
        requireUpperCase: true,
        requireLowerCase: true,
        requireNumber: true,
        requireSpecialCharacter: false,
        allowedSpecialCharacters: [],
        lockOut: {
            enabled: false,
            time: 0,
            attempts: 10
        }
    },
    registerConfig,
    navbarConfig,
    enableCountry: false,
    departmentsConfig,
    dashboardPlacesConfig,
    bannerConfig,
    hideSocialLogin: true,
    hideSocialLinks: true,
    dashboardTopPadding: "0px",
    socialConfig,
    ambassadorImage: "",
    auth,
    commentsConfig,
    eventsConfig,
    contentConfig,
    contactConfig,
    companyLogo: "https://res.cloudinary.com/energylab/image/upload/v1575037857/kpmgtogether/kpmglogo.svg",
    companyName: "Kpmg",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig,
    socialTags: ["LifeatKPMG", "TogetheratKPMG"],
    likesConfig,
    webshopConfig,
    notificationsConfig,
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig,
    nationalities,
    selectedLanguage: getBrowserLocale(routeConfig.defaultLocale, routeConfig.locales),
    seo,
    uploadPreset: "kpmgtogether",
    newUsersConfig,
    articleTagsConfig,
    nicknameConfig,
    footerConfig,
    challengeConfig,
    galleryConfig,
    logoutConfig,
    resetPasswordConfig,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex"
    ],
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    socialShareConfig,
    leaderboardConfig,
    whiteListSignUpConfig
};
