
export const nationalities = [
    {
        code: "BEL",
        name: "Belgian"
    },
    {
        code: "NLD",
        name: "Dutch"
    }
];
