import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { SEX } from "@energylab/shared-components/utils/sex";
import * as React from "react";
import * as environment from "environment";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { NicknameWrapper } from "@energylab/shared-components/components/profile/profileForm/profileFormStyle";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "auth.fields.useNickname",
            type: "checkbox",
            property: "useNickname",
            styledComponent: NicknameWrapper
        },
        {
            placeholder: "auth.fields.nickname",
            type: "text",
            property: "nickname",
            disableWhen: {
                property: "useNickname",
                valueIs: false
            },
            requireWhen: {
                property: "useNickname",
                valueIs: true
            }
        },
        {
            type: "row",
            property: "bd-sex",
            formElements: [
                {
                    title: "auth.fields.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    disabled: false,
                    validationRules: [
                        {
                            validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                        }
                    ]
                },
                {
                    title: "auth.fields.nationality",
                    type: "text",
                    defaultValue: "BEL",
                    property: "nationality",
                    disabled: true,
                    hide: true
                },
                {
                    title: "auth.fields.sex",
                    type: "radio",
                    options: [
                        { title: <FormatMessage id="male" />, value: SEX.male },
                        { title: <FormatMessage id="female" />, value: SEX.female }
                    ],
                    property: "sex",
                    required: true
                }
            ]
        }
    ]
};
